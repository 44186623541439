import React from 'react'
import PropTypes from 'prop-types'

class MatomoTrackingCode extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
    }
  }

  componentDidMount() {
    const { matomoUrl, siteId } = this.props;
    if (!this.state.initialized) {
      if (window.location.hostname !== 'localhost') {
        /* ALMOST ORIGINAL TRACKING CODE FROM MATOMO */
        var _paq = _paq || [];
        /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
        _paq.push(['trackPageView']);
        _paq.push(['enableLinkTracking']);
        (function() {
          var u=matomoUrl;
          _paq.push(['setTrackerUrl', u+'piwik.php']);
          _paq.push(['setSiteId', `${siteId}`]);
          var d=window.document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
          g.type='text/javascript'; g.async=true; g.defer=true; g.src=u+'piwik.js'; s.parentNode.insertBefore(g,s);
        })();
        window._paq = _paq;
        /* END ALMOST ORIGINAL TRACKING CODE FROM MATOMO */
      } else {
        console.warn('Matomo is inactive in local mode');
      }
      this.setState({
        initialized: true,
      })
    }
  }

  static trackArticle({ article, title, generationTime }) {
    const { _currentArticle } = MatomoTrackingCode;
    const { _paq } = window;
    
    try {
      if (!!_paq) {
        _paq.push(['setReferrerUrl', `#/${_currentArticle || ''}`]);
        _paq.push(['setCustomUrl', `#/${article || ''}`]);
        _paq.push(['setDocumentTitle', `${title || (article[0].toUpperCase() + article.substr(1))}`]);

        // remove all previously assigned custom variables, requires Matomo (formerly Piwik) 3.0.2
        _paq.push(['deleteCustomVariables', 'page']); 
        _paq.push(['setGenerationTimeMs', (generationTime || 0) * 1]);
        _paq.push(['trackPageView']);

        // enable link tracking
        _paq.push(['enableLinkTracking']);
      }
    } catch(e) {
      console.warn('Could not update article correctly.');
    }
    MatomoTrackingCode._currentArticle = article;
  }

  render() {
    return(
        <div style={{display:'none'}}></div>
    )
  }
}

MatomoTrackingCode.propTypes = {
  matomoUrl: PropTypes.string,
  siteId: PropTypes.number
}

export default MatomoTrackingCode
